<template>
  <Card v-show="false">
    <template #content>
      <div class="grid">
        <div class="col-1 md:col-1"></div>
        <div id="relatorio" class="col-10 md:col-10">
          <Cabecalho />
          <component :is="relatorioStore.nomeRelatorio"></component>
        </div>
        <div class="col-1 md:col-1"></div>
      </div>
    </template>
  </Card>
</template>

<script>
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import Cabecalho from '@/components/shared/relatorio/Cabecalho.vue'
import ReservaCartao from '@/components/shared/relatorio/movimento/ReservaCartao.vue'
import ExtratoConsignacao from '@/components/shared/relatorio/movimento/ExtratoConsignacao.vue'
import Consignacao from '@/components/shared/relatorio/movimento/Consignacao.vue'
import TaxaAssistencial from '@/components/shared/relatorio/financeiro/TaxaAssistencial.vue'
import PrestacoesDescontadas from '@/components/shared/relatorio/movimento/PrestacoesDescontadas.vue'
import RelatorioCobranca from '@/components/shared/relatorio/financeiro/RelatorioCobranca.vue'
import { storeRelatorio } from '@/stores/relatorio'
import ServidorService from '@/service/ServidorService.js'

export default {
  name: 'TempalteRelatorio',

  components: {
    Cabecalho,
    ReservaCartao,
    ExtratoConsignacao,
    Consignacao,
    TaxaAssistencial,
    PrestacoesDescontadas,
    RelatorioCobranca,
  },

  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },

  data() {
    return {
      dadosDoServidor: {},
    }
  },
  created() {
    this.servidorService = new ServidorService(this.$http)
  },

  async mounted() {
    if (this.relatorioStore.nomeRelatorio === 'ExtratoConsignacao') {
      //buscar servidor e cpf
      try {
        const servidor = await this.servidorService.buscaServidor(
          this.relatorioStore.cpf,
        )
        this.dadosDoServidor = servidor[0]
      } catch (error) {
        console.error('Erro ao buscar servidor:', error)
      }
    }

    if ('EXCEL' === this.relatorioStore.contentType) {
      await this.gerarExcel()
    } else {
      await this.gerarPDF()
    }
  },

  methods: {
    async gerarPDF() {
      const doc = new jsPDF('l', 'pt', 'a4')

      //Primeira linha do cabeçalho
      doc.setFontSize(8)
      doc.setFont('helvetica', 'bold')
      const cabecalho = document.getElementById('cabecalho').innerHTML
      doc.text(15, 30, cabecalho, {
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        padding: 5,
      })

      //Segunda linha do cabeçalho - Relatório de ExtratoConsignação
      if (this.relatorioStore.nomeRelatorio === 'ExtratoConsignacao') {
        doc.setFont('helvetica', 'bold')
        const cabecalho2 = document.getElementById('cabecalho2').innerHTML
        const textoComQuebraDelinha =
          '\n' +
          this.dadosDoServidor.nome +
          ' - ' +
          this.dadosDoServidor.cpf +
          cabecalho2
        doc.text(15, 36, textoComQuebraDelinha, {
          margin: { top: 8, right: 10, bottom: 10, left: 10 },
          padding: 5,
        })
      }

      // Título
      const titulo = document.getElementById('titulo').innerHTML
      doc.setFont('helvetica', 'bold')
      doc.setFontSize(14)
      doc.text(doc.internal.pageSize.getWidth() / 2, 70, titulo, {
        align: 'center',
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        padding: 5,
      })

      // Carregar tabela
      doc.autoTable({
        html: '#tabela',
        startY: 100,
        useCss: true,
        showFoot: 'lastPage',
        didDrawPage: function (data) {
          // Footer
          let str = 'Página ' + doc.internal.getNumberOfPages()
          doc.setFontSize(10)
          let pageSize = doc.internal.pageSize
          let pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
        },
      })

      const filename = this.relatorioStore.nomeRelatorio + '.pdf'
      doc.save(filename)
    },

    async gerarExcel() {
      // Obter a tabela de dados
      const table = document.getElementById('tabela')
      const wb = XLSX.utils.table_to_book(table)

      // Adicionar cabeçalhos ao início da planilha
      const ws = wb.Sheets[wb.SheetNames[0]]
      const cabecalho = document.getElementById('cabecalho').innerHTML

      //Estilo das células do cabeçalho
      const cellStyle = {
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      }

      // Mescla celulas para simular uma linha.
      ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }, // Mesclar primeira linha (cabeçalho)
        { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } }, // Mesclar segunda linha (dados do servidor)
        { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } }, // Mesclar terceira linha (nome do relatório)
      ]

      ws['A1'] = {
        v: cabecalho,
        t: 's',
        cellStyle,
      }
      ws['A2'] = {
        v: '',
        t: 's',
        s: cellStyle,
      }
      ws['A3'] = {
        v: this.relatorioStore.nomeRelatorio,
        t: 's',
        s: cellStyle,
      }

      //Define estrutura do relatório de Extrato Consignação
      if (this.relatorioStore.nomeRelatorio === 'ExtratoConsignacao') {
        const cabecalho2 = `SERVIDOR:  ${this.dadosDoServidor.nome}  -  CPF: ${this.dadosDoServidor.cpf}`

        ws['A2'] = {
          v: cabecalho2,
          t: 's',
          s: cellStyle,
        }
        ws['A3'] = {
          v: 'RELATÓRIO DE EXTRATO DE CONSIGNAÇÃO',
          t: 's',
          s: cellStyle,
        }
        //Ajusta largura das colunas
        ws['!cols'] = [
          { wpx: 64 },
          { wpx: 120 },
          { wpx: 40 },
          { wpx: 100 },
          { wpx: 40 },
          { wpx: 60 },
          { wpx: 120 },
          { wpx: 80 },
        ]
      }

      // Salvar a planilha com as configurações
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      })

      const blob = new Blob([wbout], {
        type: 'application/octet-stream',
      })

      saveAs(blob, `${this.relatorioStore.nomeRelatorio}.xlsx`)
    },
  },
}
</script>
