<template>
  <span>
    <h3 id="titulo" class="text-center">Relatório de Prestações Descontadas</h3>
    <div>
      <table id="tabela">
        <thead>
          <tr>
            <th>Mês/Ano</th>
            <th>Matricula</th>
            <th>CPF</th>
            <th>Nome</th>
            <th>Entidade</th>
            <th>Rubrica</th>
            <th>Consignatária</th>
            <th class="text-right">Prestação</th>
            <th class="text-right">Averbação</th>
            <th class="text-right">Valor Descontado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(prestacao, index) in prestacoesDescontadas" :key="index">
            <td>{{ prestacao.mesReferencia }}/{{ prestacao.anoReferencia }}</td>
            <td>{{ prestacao.matricula }}</td>
            <td>{{ prestacao.cpfServidor }}</td>
            <td>{{ truncateText(prestacao.nomeServidor, 30) }}</td>
            <td>{{ prestacao.nomeEntidade }}</td>
            <td>{{ prestacao.rubrica }}</td>
            <td>
              {{ truncateText(prestacao.nomeConsignataria, 30) }}
            </td>
            <td class="formatarValor">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ prestacao.valorParcela | formatarValor }}
              </span>
              <span v-else>
                {{ prestacao.valorParcela | formatarValorSemCifrao }}
              </span>
            </td>
            <td class="formatarValor">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{
                  prestacao.prazoTotal !== 0
                    ? prestacao.prazoTotal * prestacao.valorParcela
                    : prestacao.valorParcela | formatarValor
                }}
              </span>
              <span v-else>
                {{
                  prestacao.prazoTotal !== 0
                    ? prestacao.prazoTotal * prestacao.valorParcela
                    : prestacao.valorParcela | formatarValorSemCifrao
                }}
              </span>
            </td>
            <td class="formatarValor">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ prestacao.valorDescontado | formatarValor }}
              </span>
              <span v-else>
                {{ prestacao.valorDescontado | formatarValorSemCifrao }}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="7"><strong>Total:</strong></td>
            <td class="formatarValor">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ totalValorParcela() | formatarValor }}
              </span>
              <span v-else>
                {{ totalValorParcela() | formatarValorSemCifrao }}
              </span>
            </td>
            <td class="formatarValor">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ totalValorAverbacao() | formatarValor }}
              </span>
              <span v-else>
                {{ totalValorAverbacao() | formatarValorSemCifrao }}
              </span>
            </td>
            <td class="formatarValor">
              <span v-if="relatorioStore.contentType == 'PDF'">
                {{ totalValorDescontado() | formatarValor }}
              </span>
              <span v-else>
                {{ totalValorDescontado() | formatarValorSemCifrao }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </span>
</template>

<script>
import { storeRelatorio } from '@/stores/relatorio'

export default {
  setup() {
    const relatorioStore = storeRelatorio()
    return { relatorioStore }
  },

  data() {
    return {
      prestacoesDescontadas: [],
    }
  },
  created() {
    this.prestacoesDescontadas = this.relatorioStore.response
  },

  methods: {
    totalValorParcela() {
      return this.prestacoesDescontadas.reduce(
        (sum, prestacao) => sum + (prestacao.valorParcela || 0),
        0,
      )
    },
    totalValorAverbacao() {
      return this.prestacoesDescontadas.reduce((sum, prestacao) => {
        return (
          sum +
          (prestacao.prazoTotal !== 0
            ? prestacao.prazoTotal * prestacao.valorParcela
            : prestacao.valorParcela || 0)
        )
      }, 0)
    },
    totalValorDescontado() {
      return this.prestacoesDescontadas.reduce(
        (sum, prestacao) => sum + (prestacao.valorDescontado || 0),
        0,
      )
    },
    truncateText(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...'
      } else {
        return text
      }
    },
  },
}
</script>
prestacao.nomeServidor
<style lang="scss" scoped>
#titulo {
  font-weight: bold;
}

.formatarValor {
  text-align: right !important;
}

#tabela {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 11px;
}
#tabela th {
  background-color: #333;
  color: #fff;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

#tabela td {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ccc;
}

#tabela tfoot {
  background-color: #333;
  color: #fff;
  text-align: center;
  border: 1px solid #333;
}

#subTotal {
  background-color: #f2f2f2;
}

@media print {
  #titulo {
    font-weight: bold;
  }

  .formatarValor {
    text-align: right !important;
  }

  #tabela {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
    font-size: 12px;
  }
  #tabela th {
    background-color: #333;
    color: #fff;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
  }

  #tabela td {
    padding: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #tabela tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #tabela tfoot {
    background-color: #666666;
    color: #fff;
  }
}
</style>
